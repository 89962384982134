import React, { useRef } from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import ScrollContainer from 'react-indiana-drag-scroll';
import "scroll-behavior-polyfill";
import Icon from "./icon";

import * as styles from "../styles/components/about-gallery.module.css";

const Gallery = (props) => {

  const {
    gallery
  } = props;

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div className={styles.root}>
      <Container>
        <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <ScrollContainer className={styles.carousel} innerRef={ref} vertical="false" style={{scrollBehavior: 'smooth'}}>
            <button 
              className={styles.btnScroll}
              onClick={() => scroll(380)}
            >
              <Icon symbol="arrow-left-circle" />
              <span>Click+Drag</span>
            </button>
            <div className={styles.carouselInner}>
              {gallery.map((node) => {
                return (
                  <div key={node._key} className={styles.item}>
                    {node && node.asset && (
                      <figure>
                        <img
                          src={imageUrlFor(buildImageObj(node))
                            .auto("format")
                            .url()}
                          alt={node.alt}
                        />
                        {(node.caption1 || node.caption2) && (
                          <figcaption>
                            {node.caption1 && <div>{node.caption1}</div>}
                            {node.caption2 && <div>{node.caption2}</div>}
                          </figcaption>
                        )}
                      </figure>
                    )}
                  </div>
                );
              })}
            </div>
          </ScrollContainer>
        </div>
      </Container>
    </div>
  );
}

export default Gallery;
