import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import About from "../components/about";
import FooterImage from "../components/footer-image";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AboutPageQuery {
    about: sanityAbout {
      title
      featuredImage {
        ...SanityImage
        alt
      }
      _rawContent
      gallery {
        _key
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        caption1
        caption2
        alt
      }
      footerImage {
        asset {
          _id
        }
      }
      excerpt
      socialMedia {
        _key
        title
        link
      }
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const about = (data || {}).about;

  return (
    <Layout>
      <Seo
        title={about.title}
        description={about.excerpt}
      />

      {(about.title || about.socialMedia || about._rawContent || (about.featuredImage && about.featuredImage.asset) || about.gallery.length) && (
        <About title={about.title} socialMedia={about.socialMedia} featuredImage={about.featuredImage} content={about._rawContent} gallery={about.gallery} />
      )}

      {about.footerImage && (
        <FooterImage image={about.footerImage} />
      )}
    </Layout>
  );
};

export default AboutPage;
