import React, { useEffect, useContext } from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";
import Gallery from "../components/about-gallery";
import SocialMedia from "./social-media";
import { Parallax, ParallaxContext } from 'react-scroll-parallax';

import * as styles from "../styles/components/about.module.css";

const About = (props) => {

  const {
    title,
    featuredImage,
    content,
    gallery,
    socialMedia
  } = props;

  const context = useContext(ParallaxContext);

  useEffect(() => {
    if (context) {
      context.update();
    }
  });

  const onImageLoad = () => {
    if (context) {
      context.update();
    }
  }

  return (
    <div className={styles.root}>
      {featuredImage && featuredImage.asset && (
        <div className={styles.image}>
          <Parallax y={["140px", "-100px"]}>
            <img
              src={imageUrlFor(buildImageObj(featuredImage))
                .auto("format")
                .url()}
              alt={featuredImage.alt}
              onLoad={onImageLoad}
            />
          </Parallax>
        </div>
      )}
      {title && (
        <div className={styles.title}>
          <Container>
            <h1>{title}</h1>
            {socialMedia && <SocialMedia items={socialMedia} />}
          </Container>
        </div>
      )}
      {content && (
        <div className={styles.content}>
          <Container>
            <PortableText blocks={content} />
          </Container>
        </div>
      )}
      {gallery.length ? <Gallery gallery={gallery} /> : null}
    </div>
  );
}

export default About;
